export const prihodDescription = ['Горение кокса', 'Тепло нагретого дутья', 'Тепло конверсии газа'];

export const rashodDescription = ['Прямое восстановление оксидов железа',
    'Прямое восстановление примесей чугуна',
    'Расход тепла на десульфурацию чугуна',
    'Восстановление оксидов железа водородом',
    'Тепло, уносимое чугуном',
    'Тепло, уносимое шлаком',
    'Разложение влаги дутья',
    'Расход тепла на разложение известняка',
    'Расход тепла на разложение влаги шихты',
    'Тепло, уносимое колошниковым газом'
];

export const nevyazka = ['По методике А.Н. Рамма', 'По разности прихода и расхода', 'По методике А.Н. Рамма'];
