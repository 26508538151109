import React from "react";

export const Home = () => {

    return (
        <div className='content'>
            <h1>Тепловой и материальный балансы доменной плавки</h1>
            <p>
                Для реализации доменного процесса необходимо тепло. Оно расходуется на нагрев загруженных в доменную печь шихтовых материалов, плавления чу-гуна и шлака, оно необходимо для компенсации отрицательных тепловых эф-фектов восстановления оксидов железа и других элементов, для увеличения скорости течения химических реакций, сопровождающих доменный передел, для разложения гидратных и карбонатных соединений, для испарения влаги шихты и т.д.
            </p>
            <p>
                Результат тепловой работы доменной печи наглядно отражает тепловой баланс плавки. Этот баланс дает общую картину как приходной, так и расход-ной частей баланса, что позволяет выполнить обоснованный анализ тепловой работы печи и сделать конкретные выводы о возможности сокращения удельно-го расхода кокса при доменной плавке.
            </p>
            <p>
                Тепловой баланс доменной плавки выполняется на основе составленного материального баланса; он составляется на 1 кг чугуна и для наглядности отображается в графическом и табличном виде.
            </p>
        </div>
    )
}
